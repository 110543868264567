<template>
    <div>
        <b-modal
            size="lg"
            title="Freeze Tool"
            ref="modal-1"
            id="modal-1"
            ok-title="Freeze"
            cancel-title="Reset "
            hide-header
            hide-footer
            @ok="SaveFilter"
            @cancel="handleReset"
            :visible="isVisible"
            @hide="hideModal"
        >
            <div class="text-right cursor-pointer">
                <img
                    class="pointer"
                    src="../../assets/close_icon.svg"
                    @click="closeModal"
                />
            </div>
            <h5 class="my-2 title text-center">Save filters</h5>
            <div class="manage-form">
                <div class="content">
                    <CustomFloatingLabel
                        :config="{
                            label: 'Filter name',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                        v-bind:label="floatLabelAdd"
                    >
                        <input
                            id="filterName"
                            ref="filterName"
                            autocomplete="off"
                            type="text"
                            v-model="efilterName"
                            class="normal_input"
                            min="0"
                        />
                    </CustomFloatingLabel>
                    <CustomFloatingLabel
                        :config="{
                            label: 'Filter description',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                        v-bind:label="floatLabelAdd"
                    >
                        <input
                            id="filterDescription"
                            ref="filterDescription"
                            autocomplete="off"
                            type="text"
                            v-model="efilterDescription"
                            class="normal_input"
                            min="0"
                        />
                    </CustomFloatingLabel>
                    <CustomFloatingLabel
                        :config="{
                            label: 'Category',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                        v-bind:label="floatLabelAdd"
                    >
                        <input
                            id="Category"
                            ref="Category"
                            autocomplete="off"
                            type="text"
                            v-model="efilterCategory"
                            class="normal_input"
                            min="0"
                        />
                    </CustomFloatingLabel>
                    <b-button
                        id="freeze-btn-portal"
                        class="applyButton assignedApply"
                        @click="SaveFilter()"
                    >
                        Save
                    </b-button>
                    <b-button
                        id="reset-btn--portal"
                        class="cancelButton"
                        @click="forceReset"
                    >
                        Reset
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import CustomFloatingLabel from '../CustomFloatingLabel';
import { datasetService } from '../../services/dataset.service';
export default {
    components: {
        CustomFloatingLabel,
    },
    props: {
        filterName: String,
        filterDescription: String,
        filterCategory: String,
        editUserPortalId: Number,
    },
    data() {
        return {
            isVisible: false,
            floatLabelAdd: true,
            efilterName: '',
            efilterDescription: '',
            efilterCategory: '',
            eeditUserPortalId: 0,
        };
    },
    methods: {
        SaveFilter() {
            let details = {
                UserPortalOptionID: this.eeditUserPortalId,
                Name: this.efilterName,
                Description: this.efilterDescription,
                Category: this.efilterCategory,
            };

            datasetService
                .UpdateUserPortalOptionsDetails(details)
                .then((res) => {
                    this.isVisible = false;
                    this.$emit('deleteEditFilterDetails');
                })
                .catch((err) => {});
        },
        handleReset() {},
        closeModal() {
            this.isVisible = false;
            this.$emit('deleteEditFilterDetails');
        },
        forceReset() {
            this.efilterName = '';
            this.efilterDescription = '';
            this.efilterCategory = '';
        },
        hideModal() {
            this.isVisible = false;
            this.$emit('deleteEditFilterDetails');
        },
    },
    watch: {
        filterName(newVal) {
            if (newVal) {
                this.isVisible = true;
                this.efilterName = this.filterName;
                this.efilterDescription = this.filterDescription;
                this.efilterCategory = this.filterCategory;
                this.eeditUserPortalId = this.editUserPortalId;
            }
        },
    },
};
</script>

<style></style>
